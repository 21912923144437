/* eslint-disable react/self-closing-comp */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
import React, { FC } from 'react';

import Layout from '@/common/ui/template/Layout';
import SEO from '@/newcar/ui/docs/faq/organism/SEO';

import freedialGreen from '@/newcar/ui/common/img/freedial/freedial-green.svg';
import ButtonContact from '@/newcar/ui/common/img/faq/btn-contact.svg';
import ButtonContactLine from '@/newcar/ui/common/img/faq/btn-contact-line.svg';

import FAQAccordion from '@/newcar/ui/docs/faq/organism/FAQAccordion';

import Header from '@/newcar/ui/common/organism/UsedcarHeader';
import Footer from '@/newcar/ui/common/organism/UsedcarFooter';

import AnchorLink from '@/common/ui/atom/AnchorLink';
import useScrollToElementOnLoad from '@/common/ui/hook/useScrollToElementOnLoad';
import bnrCampaign from '@/newcar/ui/top/img/topbanner/lp-bnr.png';
import { Element } from 'react-scroll';

import FaqCategory from '@/common/core/model/faqCategory';
import FaqContent from '@/common/core/model/faqContent';
import Grid from '@/newcar/ui/usedcar/docs/faq/molecule/Grid';
import GridItem from '@/newcar/ui/usedcar/docs/faq/molecule/GridItem';
import Typography from '@/newcar/ui/usedcar/docs/faq/molecule/Typography';
import carPlan01 from '@/newcar/ui/usedcar/docs/faq/img/img-car-plan01.png';
import carPlan02 from '@/newcar/ui/usedcar/docs/faq/img/img-car-plan02.png';

import * as styles from './index.module.styl';

import FaqData from '../../../../../../../json/usedcar_faq.json';

const Container = ({ id, children, className }: { id: string, children: React.ReactNode, className: string }): JSX.Element => (
  <section className={className}>
    <div
      id={id}
      className="container-md my-4 mt-md-0"
    >
      {children}
    </div>
  </section>
);

type ContentTitleType = 'search';

const contentTitle: { [Key in ContentTitleType]: JSX.Element } = {
  search: (
    <Typography
      className={`${styles.searchTitleLabel} fs-3 cfs-lg-28 mt-0 mb-4`}
      type="subtitle1"
      value="中古車を探す"
    />
  ),
};

const contentSubtitle: { [key in ContentTitleType]: (
  value1: string,
  value2: string
) => JSX.Element } = {
  search: (value1, value2) => (
    <Typography
      className={styles.searchSubtitleLabel}
      type="subtitle3"
      value={(
        <>
          {value1}
          <span className="cfs-13">
            {value2}
          </span>
        </>
      )}
    />
  ),
};

const contentSubtitleRadius: { [key in ContentTitleType]: (
  value1: string,
  value2: string
) => JSX.Element } = {
  search: (value1, value2) => (
    <Typography
      className={styles.searchSubtitleLabelRadius}
      type="subtitle4"
      value={(
        <>
          {value1}
          <span className="cfs-13">
            {value2}
          </span>
        </>
      )}
    />
  ),
};

// export default function Page(): JSX.Element {
const Page: FC = () => {
  const faqCategories: FaqCategory[] = FaqData.categories;
  const faqContents: FaqContent[] = FaqData.contents;

  useScrollToElementOnLoad({ duration: 300, smooth: true, offset: -50 });

  function compareFaqByOrderBy(a: FaqContent, b: FaqContent): number {
    if (a.orderBy && b.orderBy) {
      return (a.orderBy > b.orderBy ? 1 : -1);
    } return 0;
  }

  return (
    <Layout>
      <SEO />
      <Header />
      {/* // NOTE: 中古車のヘッダ / パンくずコンポーネントを読み込む必要あり */}
      <main>
        <div className={`${styles.sectionArea} ${styles.sectionBgMain}`}>
          {/* Working area */}
          <section>
            <h2 className={`is-size-3 ${styles.mainHeading}`}>
              よくあるご質問
            </h2>
            <div className={`container ${styles.faqContainerHelper}`}>
              <section key="" className={styles.faqSection}>
                <div className={styles.innerContainer}>
                  <h3 className={`heading ${styles.faqHeading}`}>
                    よく見られているご質問
                  </h3>
                  <div className={styles.faqContainer}>
                    <ul>
                      {faqContents
                        .filter((item) => item.pick_up === true)
                        .sort(compareFaqByOrderBy)
                        .map((item) => (
                          <FAQAccordion
                            className={`js-accordion-trigger ${styles.faqList}`}
                            key={item.question}
                            qa={item}
                            goods="usedcar"
                            isFrequentlyAsked
                          />
                        ))}
                    </ul>
                    <a className={styles.faqCategoryLink} href="#categoryLink">カテゴリ選択に戻る</a>
                  </div>
                </div>
              </section>

              <section>
                <div className={styles.innerContainer}>
                  <h3 id="categoryLink" className={`heading ${styles.faqHeading}`}>
                    カテゴリから探す
                  </h3>
                  <div className={`${styles.faqContainer} ${styles.isCategory}`}>
                    <ul className={styles.list}>
                      {/* data-gtm="[種別]-faq-cat[N]" */}
                      {faqCategories.map((c) => (
                        <li key={`faq-category-link-${c.name}`}>
                          <AnchorLink
                            to={c.id ?? ''}
                            options={{ duration: 300, smooth: true, offset: -50 }}
                            className={`is-relative is-flex js-anchor ${styles.listButton}`}
                          >
                            <span
                              className={`${styles.listText}`}
                            >
                              {c.name || ''}
                            </span>
                          </AnchorLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>

              <section className="is-hidden">
                <div className={styles.innerContainer}>
                  <h3 className={`heading ${styles.faqHeading}`}>
                    キーワードから探す
                  </h3>

                  <div className={styles.faqContainer}>
                    <div className={styles.keywordInputContainer}>
                      <input
                        className={styles.keywordInputField}
                        placeholder="探したいQ&Aを入力"
                        type="text"
                      />
                      <button
                        className={styles.keywordInputButton}
                        type="submit"
                      />
                    </div>
                    <div className={styles.keywordTipContainer}>
                      <span className={styles.keywordTipItem}>黒ナンバー</span>
                      <span className={styles.keywordTipItem}>審査</span>
                      <span className={styles.keywordTipItem}>家族契約</span>
                      <span className={styles.keywordTipItem}>メンテナンスプラン</span>
                      <span className={styles.keywordTipItem}>連帯保証人</span>
                      <span className={styles.keywordTipItem}>年齢に制限</span>
                      <span className={styles.keywordTipItem}>法人名義</span>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className={styles.innerContainer}>
                  <div className={`faq ${styles.contentSpacing}`}>
                    {faqCategories.map((c) => (
                      <section
                        key={`faq-category-${c.name}`}
                        className={styles.faqSection}
                      >
                        <Element
                          name={c.id ?? ''}
                          id={c.id ?? ''}
                        >
                          <h3 className={`heading ${styles.faqHeading}`}>
                            {c.name}
                          </h3>
                        </Element>
                        <div className={styles.faqContainer}>
                          <ul>
                            {faqContents
                              .filter((item) => item.category === c.id)
                              .map((item) => (
                                <FAQAccordion
                                  className={`js-accordion-trigger ${styles.faqList}`}
                                  key={item.question}
                                  qa={item}
                                  goods="usedcar"
                                  isFrequentlyAsked={false}
                                />
                              ))}
                          </ul>
                          <a className={styles.faqCategoryLink} href="#categoryLink">カテゴリ選択に戻る</a>
                        </div>
                      </section>
                    ))}
                  </div>
                </div>
              </section>

              <section>
                <div className={styles.innerContainer}>
                  <h3 className={`heading ${styles.faqHeading}`}>
                    お問合わせ
                  </h3>
                  <p className={styles.faqContactText}>
                    こちらのページで解決できないことは、お気軽に
                    <br />
                    お問合わせください。
                  </p>
                  <div className={styles.faqContactContainer}>
                    <div className={styles.faqContactCard}>
                      <h4>メールでのお問合わせ</h4>
                      <a href="/contact/">
                        <img src={ButtonContact} alt="0120-559-442" />
                      </a>
                    </div>
                    <div className={styles.faqContactCard}>
                      <h4>
                        定額カルモくん公式LINEアカウント
                      </h4>
                      <a href="https://liff.line.me/1657071928-nlq7zlNm/fed67fbd12124b3b81383cd06a9881eb">
                        <img src={ButtonContactLine} alt="0120-559-442" />
                      </a>
                    </div>
                    <div className={styles.faqContactCard} style={{ display: 'none' }}>
                      <h4>お電話でのお問合わせ</h4>
                      <a href="tel:0120-559-442" className={styles.faqContactLinkTel}>
                        <img src={freedialGreen} alt="0120-559-442" />
                      </a>
                      <span className={styles.faqContactTextMin}>受付時間：平日10:00〜19:00（土日・祝日・年末年始休み）</span>
                    </div>
                  </div>
                </div>
              </section>

              <section style={{ display: 'none' }}>
                <div className="is-pc-bg" id="sectionOrdertype">
                  <h2 className={styles.ordertypeListDivideFirst}>厳選中古車お申し込み</h2>
                  <div className="section-body is-ordertype px-c6 px-md-0">
                    <ul className="ordertype-list-container">
                      {/* <!-- ordertype-card01 --> */}
                      <li id="sectionOrdertypeOne" className="ordertype-list-item">
                        <div className="ordertype-list-child">
                          <h3 className="ordertype-name">
                            お手頃な軽自動車プラン
                          </h3>
                          <figure className="ordertype-figure">
                            <img className="ordertype-figure-image" src={carPlan01} alt="厳選中古車プラン" />
                          </figure>
                          <div className="ordertype-middle-container">
                            <p className="ordertype-description is-first">
                              NBOX、ミライース、ハスラーなど人気の軽自動車をご希望の方はこちらのプランからお申し込みください。
                            </p>
                          </div>
                          <div className="ordertype-price-wrapper">
                            <span className="ordertype-baloon">頭金・ボーナス払い0円</span>
                            <p className="ordertype-fee-container">
                              <span className="ordertype-fee-monthly is-first">月額</span>
                              <span className="ordertype-fee-number-large">1.</span>
                              <span className="ordertype-fee-number-middle">6</span>
                              <span className="ordertype-fee-unit">万円</span>
                              <span className="ordertype-fee-range">〜</span>
                              <span className="ordertype-fee-tax">（税込）</span>
                            </p>
                            <a
                              className="ordertype-button"
                              href="/contract/redirect/10017/?carId=10017&price=12820&plan=%E8%AA%8D%E5%AE%9A%E4%B8%AD%E5%8F%A4%E8%BB%8A_%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF_%E4%B8%AD%E5%8F%A4&from=https%3A%2F%2Fcarmo-kun.jp%2Fusedcar%2Fcpb%2F"
                              data-gtm="usedcar_lp_quick_mira_1"
                              role="button"
                            >
                              まずはお試しで審査に申し込む
                            </a>
                            <details className="ordertype-details mt-3 my-md-auto">
                              <summary className="ordertype-summary">
                              </summary>
                              <div className="ordertype-details-content">
                                <ul className="ordertype-details-dl-list-container">
                                  <li className="ordertype-details-list-item cfw-700 mb-1">審査申込車種：ダイハツ ミライース</li>
                                  <li className="ordertype-details-list-item cfw-700 mb-2">月額料金：12,820円（税込）</li>
                                  <li className="ordertype-details-list-item">グレード：B</li>
                                  <li className="ordertype-details-list-item">リース期間：11年（132回払い）</li>
                                  <li className="ordertype-details-list-item">オプション装備：なし</li>
                                  <li className="ordertype-details-list-item">メンテナンスプラン：なし</li>
                                  <li className="ordertype-details-list-item">もらえるオプション：なし</li>
                                  <li className="ordertype-details-list-item">頭金・ボーナス払い：なし</li>
                                </ul>
                              </div>
                            </details>
                          </div>
                        </div>
                      </li>
                      {/* <!-- // ordertype-card01 --> */}

                      {/* <!-- ordertype-card02 --> */}
                      <li id="sectionOrdertypeOne" className="ordertype-list-item">
                        <div className="ordertype-list-child">
                          <h3 className="ordertype-name">
                            大人気の普通車プラン
                          </h3>
                          <figure className="ordertype-figure">
                            <img className="ordertype-figure-image" src={carPlan02} alt="厳選中古車プラン" />
                          </figure>
                          <div className="ordertype-middle-container">
                            <p className="ordertype-description is-first">
                              ヤリス、ライズ、シエンタなど人気のおクルマをご希望の方はこちらのプランからお申し込みください。
                            </p>
                          </div>
                          <div className="ordertype-price-wrapper">
                            <span className="ordertype-baloon">頭金・ボーナス払い0円</span>
                            <p className="ordertype-fee-container">
                              <span className="ordertype-fee-monthly is-second">月額</span>
                              <span className="ordertype-fee-number-large is-second">2.</span>
                              <span className="ordertype-fee-number-middle">8</span>
                              <span className="ordertype-fee-unit">万円</span>
                              <span className="ordertype-fee-range">〜</span>
                              <span className="ordertype-fee-tax">（税込）</span>
                            </p>
                            <a
                              className="ordertype-button"
                              href="/contract/contact_info/?carId=10168&price=24480&plan=%E8%AA%8D%E5%AE%9A%E4%B8%AD%E5%8F%A4%E8%BB%8A_%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF_%E4%B8%AD%E5%8F%A4&from=https%3A%2F%2Fcarmo-kun.jp%2Fusedcar%2Fcpb%2F"
                              data-gtm="usedcar_lp_quick_mira_1"
                              role="button"
                            >
                              まずはお試しで審査に申し込む
                            </a>
                            <details className="ordertype-details mt-3 my-md-auto">
                              <summary className="ordertype-summary">
                              </summary>
                              <div className="ordertype-details-content">
                                <ul className="ordertype-details-dl-list-container">
                                  <li className="ordertype-details-list-item cfw-700 mb-1">審査申込車種：トヨタ ライズ</li>
                                  <li className="ordertype-details-list-item cfw-700 mb-2">月額料金：24,480円（税込）</li>
                                  <li className="ordertype-details-list-item">グレード：X</li>
                                  <li className="ordertype-details-list-item">リース期間：11年（132回払い）</li>
                                  <li className="ordertype-details-list-item">オプション装備：なし</li>
                                  <li className="ordertype-details-list-item">メンテナンスプラン：なし</li>
                                  <li className="ordertype-details-list-item">もらえるオプション：なし</li>
                                  <li className="ordertype-details-list-item">頭金・ボーナス払い：なし</li>
                                </ul>
                              </div>
                            </details>
                          </div>
                        </div>
                      </li>
                      {/* <!-- // ordertype-card02 --> */}
                    </ul>
                  </div>
                </div>
              </section>

              <section style={{ display: 'none' }}>
                <div className={styles.faqFootContainer}>
                  <div className={styles.faqFootContainerBody}>
                    <div className={styles.faqFootBannerContainer}>
                      <a href="/pr/2023/summer-2/">
                        <img src={bnrCampaign} alt="最大3万円が当たる！サマーキャンペーン2023" />
                      </a>
                    </div>
                  </div>
                </div>
                <Container id="top-search-area" className={`${styles.searchSection}`}>
                  <Grid
                    className={`${styles.isInner} ${styles.isSearch} bg-white mt-sm-2 mt-md-0`}
                    rowClassName="justify-content-between"
                    style={{ maxWidth: '' }}
                  >
                    <GridItem>
                      {contentTitle.search}
                    </GridItem>
                    <GridItem xs={12} lg={4} className="px-c3">
                      {contentSubtitle.search('月額料金', 'から探す')}
                      {/* <SearchMonthlyFee className="mt-3 mb-c6" /> */}
                    </GridItem>
                    <GridItem xs={12} lg={4} className="px-c3">
                      {contentSubtitle.search('キーワード', 'から探す')}
                      {/* <SearchKeyword className="mt-3 mb-c6" /> */}
                    </GridItem>
                    <GridItem xs={12} lg={4} className={`${styles.isOrderMobile} px-3`}>
                      {contentSubtitle.search('詳細条件', 'を指定して探す')}
                      {/* <SearchDetailModal className="mt-3 mb-2 mb-md-c8 mb-lg-c6 p-0" /> */}
                    </GridItem>
                    <GridItem xs={12} lg={6} className={`${styles.searchMakerContainer} px-c3 px-lg-0 ms-lg-c3`}>
                      {contentSubtitleRadius.search('メーカー', 'から探す')}
                      {/* <SearchMaker className="mb-c6 mb-lg-0" /> */}
                    </GridItem>
                    <GridItem xs={12} lg={6} className={`${styles.searchTypeContainer} px-c3 px-lg-0 me-lg-c3`}>
                      {contentSubtitleRadius.search('車のタイプ', 'から探す')}
                      {/* <SearchBodyType className="mb-c6 mb-lg-0" /> */}
                    </GridItem>
                  </Grid>
                </Container>

              </section>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </Layout>
  );
};

export default Page;

import React from 'react';
import { StandardProps } from '@/newcar/ui/usedcar/docs/faq/molecule';

interface BaseGridItemFieldProps
  extends StandardProps {
  id?: string;
  children: React.ReactNode
  xs?: number
  sm?: number
  md?: number
  lg?: number
}
// #endregion

declare type GridItemProps = BaseGridItemFieldProps;

/**
 * Bootstrapのグリッドシステム{col}に該当するコンポーネント
 *
 * 詳細: https://getbootstrap.jp/docs/5.0/layout/grid/
 *
 * @export
 * @param {GridItemProps} props
 * @return {*}  {JSX.Element}
 */
export default function GridItem(props: GridItemProps): JSX.Element {
  const {
    id = '',
    className = '',
    children,
    xs = 12,
    sm,
    md,
    lg,
    style = {},
  } = props;
  const col = {
    xs: `col-${xs}`,
    sm: sm ? `col-sm-${sm}` : '',
    md: md ? `col-md-${md}` : '',
    lg: lg ? `col-lg-${lg}` : '',
  };
  const colClass = `${Object.values(col).join(' ').trim()} ${className ?? ''}`;
  return (
    <>
      <div
        id={id}
        className={colClass}
        style={style}
      >
        {children}
      </div>
    </>
  );
}

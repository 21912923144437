import React from 'react';
import { StandardProps } from '@/newcar/ui/usedcar/docs/faq/molecule';
import LabelRequired from '@/newcar/ui/usedcar/docs/faq/molecule/Typography/atom/LabelRequired';
import LabelElective from '@/newcar/ui/usedcar/docs/faq/molecule/Typography/atom/LabelElective';

type TypographyType = 'title' | 'subtitle1' | 'subtitle2' | 'subtitle3' | 'subtitle4' | 'body' | 'caption' | 'error';
type IconType = 'none' | 'required' | 'elective';

// #region declare props
interface BaseTypographyProps
  extends StandardProps {
  /**
   * @requires
   */
  value: string | React.ReactNode;

  /**
   * @default body
   */
  type?: TypographyType

  /**
   * @default none
   */
  icon?: IconType

  /**
   * @ignore
   */
  children?: never;

  /**
   * @default true
   */
  visible?: boolean;
}
// #endregion

const iconTypeElements: { [Key in IconType]: React.ReactElement } = {
  none: <></>,
  required: <LabelRequired />,
  elective: <LabelElective />,
};

export declare type TypographyProps = BaseTypographyProps;
/**
 * 注意書きや注釈用のコンポーネント
 *
 * @export
 * @param {TypographyProps} props
 * @return {*}  {JSX.Element}
 */
export default function Typography(props: TypographyProps): JSX.Element {
  const {
    className = '',
    icon = 'none',
    value,
    type = 'body',
  } = props;
  switch (type) {
    case 'caption':
      return (
        <div
          className={`cfs-14 ${className}`}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {iconTypeElements[icon]}
          {value}
        </div>
      );
    case 'title':
      return (
        <h1 className={`cfs-28 cfw-700 my-c3 ${className}`}>
          {iconTypeElements[icon]}
          {value}
        </h1>
      );
    case 'subtitle1':
      return (
        <h2 className={`cfs-20 cfw-700 my-c2 ${className}`}>
          {iconTypeElements[icon]}
          {value}
        </h2>
      );
    case 'subtitle2':
      return (
        <h3 className={`cfs-14 cfw-600 my-c2 ${className}`}>
          {iconTypeElements[icon]}
          {value}
        </h3>
      );
    case 'subtitle3':
      return (
        <h3 className={`cfs-16 cfw-600 ${className}`}>
          {iconTypeElements[icon]}
          {value}
        </h3>
      );
    case 'subtitle4':
      return (
        <h3 className={`cfs-16 cfw-600 ${className}`}>
          {iconTypeElements[icon]}
          {value}
        </h3>
      );
    case 'body':
      return (
        <span
          className={`cfs-12 cfw-400 my-c3 ${className}`}
          style={{ whiteSpace: 'nowrap' }}
        >
          {iconTypeElements[icon]}
          {value}
        </span>
      );
    case 'error':
      return (
        <div
          className={`help text-danger ${className}`}
          style={{ minHeight: '0.95rem' }}
        >
          {iconTypeElements[icon]}
          {value}
        </div>
      );
    default:
      return <></>;
  }
}

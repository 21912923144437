import React from 'react';
import { StandardProps } from '@/newcar/ui/usedcar/docs/faq/molecule';

interface BaseGridFieldProps
  extends StandardProps {
  /**
   * @requires
   */
  children: React.ReactNode;

  /**
   * @default false
   */
  fullWidth?: boolean;
  rowClassName?: string;
}
// #endregion

declare type GridProps = BaseGridFieldProps;

const CONTAINER_MAX_WIDTH = '30rem';

/**
 * Bootstrapのグリッドシステム{container}に該当するコンポーネント
 *
 * 詳細: https://getbootstrap.jp/docs/5.0/layout/grid/
 *
 * @export
 * @param {GridProps} props
 * @return {*}  {JSX.Element}
 */
export default function Grid(props: GridProps): JSX.Element {
  const {
    className = '',
    rowClassName = '',
    children,
    fullWidth = false,
    style = { maxWidth: fullWidth ? '' : CONTAINER_MAX_WIDTH },
  } = props;
  return (
    <>
      <div
        className={`${fullWidth ? 'container-fluid' : 'container-md'} ${className ?? ''}`}
        style={style}
      >
        <div className={`row ${rowClassName}`}>
          {children}
        </div>
      </div>
    </>
  );
}

import usedcarLogo from '@/newcar/ui/common/img/logo/logo_usedcar.svg';
import beginnerIcon from '@/newcar/ui/common/img/object/icon/beginner-icon.svg';
import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from './UsedcarHeader.module.styl';

interface State {
  isMenuActive: boolean
}

type HeaderProps = {
  h1Text?: string;
}

const UsedcarHeader: FC<HeaderProps> = ({ children, h1Text }) => {
  const [state, setState] = useState<State>({
    isMenuActive: false,
  });

  const toggleMenu = (): void => {
    const { isMenuActive } = state;
    setState({
      isMenuActive: !isMenuActive,
    });
  };

  return (
    <header className="is-full-width no_fixed is-zindex-menu">
      <nav
        className={`${styles.container} container navbar is-zindex-menu`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/" className="is-flex has_vertical_centered">
              <div className="">
                <img
                  className="is-full-width"
                  src={usedcarLogo}
                  alt={h1Text || 'おトクにマイカー 定額カルモくん'}
                />
              </div>
            </Link>
          </div>
          <div className="is-flex beginner-icon is-hidden-desktop">
            <Link to="/docs/first-guide/">
              <img
                src={beginnerIcon}
                alt="はじめての方へ"
                className="is-vertical-align-middel"
              />
            </Link>
          </div>
          <div
            onClick={toggleMenu}
            role="button"
            className={`navbar-burger burger navbtn ${
              state.isMenuActive ? 'is-active' : ''
            }`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarManus"
            tabIndex={0}
            id="nvbtn"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </div>

        <div className={`${styles.beginnerIcon} beginner-icon is-flex is-hidden-touch`}>
          <Link to={NEWCAR_DEFINE.PATH.USEDCAR_FIRST_GUIDE}>
            <img
              src={beginnerIcon}
              alt="定額カルモくんの中古車リースとは？"
              className="is-vertical-align-middel"
            />
          </Link>
          <p className="popup">定額カルモくんの中古車リースとは？</p>
        </div>

        <div
          id="navbarManus"
          className={`navbar-menu ${state.isMenuActive ? 'is-active' : ''}`}
        >
          <div className="navbar-start" />

          <div className="navbar-end">
            <div className={`${styles.menu_desktop} navbar-item is-hoverable has-dropdown has-text-black-ter`}>
              <a className={`${styles.menu_item_link} navbar-item`} href="/usedcar/lineup/">
                中古車一覧
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/usedcar/docs/first-guide/">
                定額カルモくんの中古車リースとは？
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/usedcar/docs/maintenance/">
                メンテナンスプラン
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/usedcar/docs/contract/">
                ご納車までの流れ
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/usedcar/docs/pricing/">
                月額料金に含まれるもの
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/usedcar/docs/faq/">
                Q&A
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/usedcar/contact/">
                お問合せ
              </a>
            </div>

            <div className="navbar-item is-hoverable has-dropdown has-text-black-ter">
              <div role="button" className={`navbar-link ${styles.navbarLink}`} onClick={toggleMenu} tabIndex={0}>
                メニュー
              </div>

              <div className="navbar-dropdown">
                <Link className={`${styles.menu_item_link} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_LINEUP_TOP}>
                  中古車一覧
                </Link>
                <Link className={`${styles.menu_item_link} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_FIRST_GUIDE}>
                  定額カルモくんの中古車リースとは？
                </Link>
                <Link
                  className={`${styles.menu_item_link} navbar-item`}
                  to={NEWCAR_DEFINE.PATH.USEDCAR_MAINTENANCE}
                >
                  メンテナンスプラン
                </Link>
                <Link className={`${styles.menu_item_link} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_CONTRACT_FLOW}>
                  ご納車までの流れ
                </Link>
                <Link className={`${styles.menu_item_link} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_PRICING}>
                  月額料金に含まれるもの
                </Link>
                <Link className={`${styles.menu_item_link} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_FAQ}>
                  Q&A
                </Link>
                <Link className={`${styles.menu_item_link} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_CONTACT}>
                  お問合せ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {children}
    </header>
  );
};

export default UsedcarHeader;

// extracted by mini-css-extract-plugin
export const sectionArea = "index-module--section-area--2iOcc";
export const innerContainer = "index-module--inner-container--YF6_C";
export const sectionBgMain = "index-module--section-bg-main--3Uvb5";
export const whiteSpaceNowrap = "index-module--white-space-nowrap--hCvIh";
export const pagetop = "index-module--pagetop--2EtKu";
export const pagetopShow = "index-module--pagetop-show--3hWiy";
export const contentBody = "index-module--content-body--izLHD";
export const contentBodyList = "index-module--content-body-list--2OAH-";
export const mainHeading = "index-module--main-heading--3UjhC";
export const headingBold = "index-module--heading-bold--28mtb";
export const fap_contact = "index-module--fap_contact--31M6t";
export const contentText = "index-module--content-text--zF_F7";
export const gridArea = "index-module--grid-area--CJ6Fb";
export const fapContactlist = "index-module--fap-contactlist--1zuJM";
export const line = "index-module--line--3gubr";
export const btn = "index-module--btn--juT5d";
export const iconLine = "index-module--icon-line--RtMaA";
export const cLogoCarmo = "index-module--c-logo--carmo--3V-mz";
export const cLogoCarmoWhite = "index-module--c-logo--carmo-white--3Kg6c";
export const cLogoLine = "index-module--c-logo--line--3XL4X";
export const list = "index-module--list--1A1WA";
export const listText = "index-module--list-text--3OHdn";
export const listButton = "index-module--list-button--1-pli";
export const listButtonNavigation = "index-module--list-button-navigation--1YBBM";
export const listButtonNews = "index-module--list-button-news--30Bb2";
export const icoArrow = "index-module--ico-arrow--18kGq";
export const icoArrowBack = "index-module--ico-arrow-back--3uyBm";
export const iconArrowDown = "index-module--icon-arrow-down--1NNNo";
export const listArrow = "index-module--list-arrow--247OW";
export const listArrowDown = "index-module--list-arrow-down--3c2EL";
export const listArrowLeft = "index-module--list-arrow-left--2MBMW";
export const faqHeading = "index-module--faq-heading--39Esr";
export const faqContainer = "index-module--faq-container--VZiS6";
export const isCategory = "index-module--is-category--2BGwd";
export const faqList = "index-module--faq-list--s51_I";
export const faqContactContainer = "index-module--faq-contact-container--jz5ZS";
export const faqContactCard = "index-module--faq-contact-card--8Yjbn";
export const faqContactLinkTel = "index-module--faq-contact-link-tel--3-bmh";
export const faqContactText = "index-module--faq-contact-text--1IoFi";
export const faqContactTextMin = "index-module--faq-contact-text-min--37D1f";
export const faqCategoryLink = "index-module--faq-category-link--26BWA";
export const faqContainerHelper = "index-module--faq-container-helper--2TmYp";
export const faqCharmContainerHelper = "index-module--faq-charm-container-helper--3A0RA";
export const faqFootContainer = "index-module--faq-foot-container--3vIW7";
export const faqFootContainerBody = "index-module--faq-foot-container-body--bVU96";
export const faqFootBannerContainer = "index-module--faq-foot-banner-container--1N2No";
export const hasUnderline = "index-module--has-underline--2zcFF";
export const keywordInputContainer = "index-module--keyword-input-container--ax6kL";
export const keywordInputField = "index-module--keyword-input-field--3mq8L";
export const keywordInputButton = "index-module--keyword-input-button--2GEN-";
export const keywordTipContainer = "index-module--keyword-tip-container--1-ODk";
export const keywordTipItem = "index-module--keyword-tip-item--38Hrx";
export const sectionHeader = "index-module--section-header--2TTZp";
export const sectionHeaderLogo = "index-module--section-header-logo--3x0TJ";
export const sectionHeading = "index-module--section-heading--3NgQs";
export const isFirst = "index-module--is-first--3gXws";
export const isSecond = "index-module--is-second--3j55x";
export const isThird = "index-module--is-third--1R5Co";
export const isFourth = "index-module--is-fourth--UF5du";
export const isInquiry = "index-module--is-inquiry--3tGjW";
export const hasButtonToLineup = "index-module--has-button-to-lineup--2Zci7";
export const sectionSubheading = "index-module--section-subheading--5UfjE";
export const isMultiline = "index-module--is-multiline--14__p";
export const sectionSubheadingUnderline = "index-module--section-subheading-underline--1Vi2J";
export const sectionHeadingFourth = "index-module--section-heading-fourth--3DSwI";
export const sectionBody = "index-module--section-body--2MVcs";
export const isStep = "index-module--is-step--1M1Kh";
export const isNavigation = "index-module--is-navigation--3O0Sa";
export const isQa = "index-module--is-qa--A6CQe";
export const isOrdertype = "index-module--is-ordertype--3Ojbk";
export const sectionTextBrown = "index-module--section-text-brown--2miEK";
export const ordertypeCaption = "index-module--ordertype-caption--2GGAC";
export const ordertypeNotice = "index-module--ordertype-notice--3dOlx";
export const ordertypeListContainer = "index-module--ordertype-list-container--1HepO";
export const ordertypeListItem = "index-module--ordertype-list-item--2DwVS";
export const ordertypeListChild = "index-module--ordertype-list-child--rin4D";
export const isUnused = "index-module--is-unused---hY4f";
export const ordertypeListDivideFirst = "index-module--ordertype-list-divide-first--1OCAW";
export const ordertypeListDivide = "index-module--ordertype-list-divide--37Lfg";
export const ordertypeName = "index-module--ordertype-name--PMKu0";
export const ordertypeNameMin = "index-module--ordertype-name-min--2i2ht";
export const ordertypeFeature = "index-module--ordertype-feature--1dKR6";
export const ordertypeFeatureTip = "index-module--ordertype-feature-tip--3UELW";
export const ordertypeDescription = "index-module--ordertype-description--3Icd-";
export const ordertypeSubheding = "index-module--ordertype-subheding--3EaDH";
export const ordertypeMiddleContainer = "index-module--ordertype-middle-container--EdfcW";
export const ordertypeFigure = "index-module--ordertype-figure--yorOU";
export const ordertypeFigureImage = "index-module--ordertype-figure-image--PuXmP";
export const ordertypeAblelistContainer = "index-module--ordertype-ablelist-container--31kA8";
export const isSuv = "index-module--is-suv--3Y48A";
export const ordertypeAblelistItem = "index-module--ordertype-ablelist-item--ixw9b";
export const ordertypePriceWrapper = "index-module--ordertype-price-wrapper--zDhBz";
export const ordertypeButton = "index-module--ordertype-button--YoHRn";
export const ordertypeAccordionTrigger = "index-module--ordertype-accordion-trigger--pplR_";
export const ordertypeBaloon = "index-module--ordertype-baloon--IwIMD";
export const ordertypeFeeContainer = "index-module--ordertype-fee-container--22fRy";
export const ordertypeFeeMonthly = "index-module--ordertype-fee-monthly--3N3S7";
export const ordertypeFeeUnit = "index-module--ordertype-fee-unit--srPDZ";
export const ordertypeFeeNumberLarge = "index-module--ordertype-fee-number-large--2XZ22";
export const ordertypeFeeNumberMiddle = "index-module--ordertype-fee-number-middle--qlKdE";
export const ordertypeFeeRange = "index-module--ordertype-fee-range--3X4mm";
export const ordertypeFeeTax = "index-module--ordertype-fee-tax--2tmvV";
export const ordertypeDetailsContent = "index-module--ordertype-details-content--2LNUZ";
export const ordertypeDetailsListItem = "index-module--ordertype-details-list-item--2wtkj";
export const ordertypeDetails = "index-module--ordertype-details--2-JLY";
export const ordertypeSummary = "index-module--ordertype-summary--1W-Tl";